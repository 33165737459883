import './header.css'
import React from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

function refreshPage() {
    window.location.reload();
    window.scrollTo(0, 0);
}

class Header extends React.Component {
    state = {
        darkenHeader: false
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll)
    }
    handleScroll = () => {
        if (window.scrollY > 100) {
            this.setState({ darkenHeader: true })
        } else {
            this.setState({ darkenHeader: false })
        }
    }
    render() {
        const { darkenHeader } = this.state
        let headerDiv = 'Header-div'
        if (darkenHeader) {
            headerDiv += ' darkenHeader'
        }
        return (
            <header className='Header-header'>
                <div className={headerDiv}>
                    <ul className='Header-ul'>
                        <li className='Home-li' onClick={refreshPage}><Link className='Home-Link' to='/home'>Home</Link></li>
                        <li className='Schedule-li' onClick={refreshPage}><Link className='Schedule-Link' to='/schedule'>Schedule</Link></li>
                        {/* <li className='Blog-li'><Link className='Blog-Link' to='/blog'>Blog</Link></li> */}
                    </ul>
                </div>
            </header>
        )
    }
}
export default withRouter(Header);