import './footer.css'
import React from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

function refreshPage() {
    window.location.reload();
    window.scrollTo(0, 0);
}

class Footer extends React.Component {
    render() {
        return (
            <footer className='Footer-footer'>
                <div className='Footer-div'>
                    <ul className='Footer-ul'>
                        <li className='Disclaimer-li' onClick={refreshPage}><Link className='Disclaimer-Link' to='/disclaimer'>Disclaimer</Link></li>
                        <li className='Pipe-li'>|</li>
                        <li className='Years-li'>&copy; {new Date().getFullYear()} Nutrition TLC</li>
                    </ul>
                </div>
            </footer>
        )
    }
}
export default withRouter(Footer);