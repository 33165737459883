import './index.css'
import Footer from './components/footer/footer'
import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Home from './pages/home/home'
import Schedule from './pages/schedule/schedule'
import Disclaimer from './pages/disclaimer/disclaimer'
import Author from './pages/author/author'
import Header from './components/header/header'
/* import Blog from './pages/blog/blog' */

class App extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <Switch>
          <Route exact path="/home" component={Home} />
          <Route exact path="/schedule" component={Schedule} />
          <Route exact path="/disclaimer" component={Disclaimer} />
          {/* <Route exact path="/blog" component={Blog} /> */}
          <Route exact path="/author" component={Author} />
          <Redirect to="/home" />
        </Switch>
        <Footer />
      </div>
    )
  }
}

export default App;