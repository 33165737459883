import React from 'react'

class Author extends React.Component {
  render() {
    return <container className='Author-container'>
      <div class="Author-div">
        <p className='Author-p'>
          <span className='Author-span'>This website was created by...
            <br />
            Alex Jannatpour and Kevin Johsnon!
          </span>
        </p>
        <iframe className='Author-iframe' src="https://giphy.com/embed/XROOE9NApITmCgF6dZ" title='Author-iframe'></iframe>
      </div>
    </container>
  }
}

export default Author;