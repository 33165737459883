import "./index.css"
import './pages/author/author.css';
import './pages/disclaimer/disclaimer.css';
import './pages/home/home.css';
import './pages/schedule/schedule.css';
import './pages/blog/blog.css';
import App from "./App";
import BrowserRouter from "react-router-dom/BrowserRouter";
import React from "react";
import ReactDOM from "react-dom";

const seasonColor = new Date(),
  month = seasonColor.getMonth() + 1;

const January = 1;
const February = 2;
const March = 3;
const April = 4;
const May = 5;
const June = 6;
const July = 7;
const August = 8;
const September = 9;
const October = 10;
const November = 11;
const December = 12;

const springColor = '#ccffd2';/* light green */
const summerColor = '#f9ffcc';/* light yellow */
const fallColor = '#ffd2cc';/* light orange */
const winterColor = '#e6f5ff';/* light blue */
const defaultColor = 'none';

if (month === March || month === April || month === May) {
  document.body.style.backgroundColor = springColor;
  document.documentElement.style.setProperty('--seasonColor', springColor);
}
if (month === June || month === July || month === August) {
  document.body.style.backgroundColor = summerColor;
  document.documentElement.style.setProperty('--seasonColor', summerColor);
}
if (month === September || month === October || month === November) {
  document.body.style.backgroundColor = fallColor;
  document.documentElement.style.setProperty('--seasonColor', fallColor);
}
if (month === December || month === January || month === February) {
  document.body.style.backgroundColor = winterColor;
  document.documentElement.style.setProperty('--seasonColor', winterColor);
}
else {
  document.body.style.backgroundColor = defaultColor;
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);