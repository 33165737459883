import React from 'react'

class Home extends React.Component {
  render() {
    return (
      <>
        <container className='Home-container'>
          <div className='Home-div'>
            <div className='Banner-div'>
              <h1 className="Banner-h1">Your nutrition.</h1>
              <h1 className="Banner-h1">Your life.</h1>
            </div>
            <div class="Bottom-div">
              <h1 className='Bottom-h1'>Nutrition TLC</h1>
              <figure className='Bottom-figure'>
                <img className='Bottom-img' alt="ShannonOne" />
                <figcaption className='Bottom-figcaption'>Shannon Jannatpour</figcaption>
                <figcaption className='Bottom-figcaption'>MS, RDN, LDN, IFNCP, CLT, CCRP</figcaption>
              </figure>
              <p className='Bottom-p'>
                Just like a car or musical instrument, we all need a tune-up from time to time.
              </p>
              <p className='Bottom-p'>
                My mission is to improve the health and wellbeing of my clients through functional,
                integrative, individualized nutrition so they can feel their best, whether they
                suffer from arthritis, eczema, eosinophilic esophagitis, migraine headaches, irritable
                bowel syndrome, obesity and overweight, lymphedema, chronic congestion/sinusitis,
                fibromyalgia, chronic fatigue syndrome, or other digestive issues such as celiac disease.
              </p>
              <p className='Bottom-p'>
                There are many food sensitivity tests out there such as LCAT, IgG, and Everlywell, but I have
                found the mediator release test (MRT) with an individualized elimination diet (and then reintroduction)
                to be most effective. I specialize in guiding you through this whole process in a nutritionally
                sound manner to help reduce inflammation and repair the gut.
              </p>
              <p className='Bottom-p'>
                Schedule an appointment today or contact me to learn more about how nutrition therapy can help you!
              </p>
            </div>
          </div>
        </container>

      </>
    )
  }
}

export default Home;