import React from 'react'

class Schedule extends React.Component {
  render() {
    return (
      <container className='Schedule-container'>
        <div class="Schedule-div">
          <iframe className='Schedule-iframe' src='https://calendly.com/nutritiontlc/consultation-30-min' title='iframeCalendly'></iframe>
        </div>
      </container>)
  }
}

export default Schedule;